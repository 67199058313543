import { render, staticRenderFns } from "./TickerValueType.vue?vue&type=template&id=57c32164&scoped=true"
import script from "./TickerValueType.vue?vue&type=script&lang=ts"
export * from "./TickerValueType.vue?vue&type=script&lang=ts"
import style0 from "./TickerValueType.vue?vue&type=style&index=0&id=57c32164&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57c32164",
  null
  
)

export default component.exports